import { LEOThemeConfig } from "LEOTheme/utils/type-utils";

export const leoThemeConfig: LEOThemeConfig = {
  disableProfilePictures: false,
  maskDatadogActions: true,
  appMetaData: {
    appTitle: "FREDDI",
    contactName: "Mai-Britt Lundtoft",
    contactEmail: "fredditeam@leo-pharma.com",
    byline: "Developed by Site Start-Up & R&D DSAI",
    feedbackChannel: {
      teamsChannelURL:
        "https://teams.microsoft.com/l/team/19%3ab9JabW12LgYkwEIgyYMcUoWa1XEeAgiWnjrRRNqQWTo1%40thread.tacv2/conversations?groupId=f311b68f-4ca0-42de-b555-b97543bccdec&tenantId=d78f7362-832c-4715-8e12-cc7bd574144c",
    },
  },
  disableGQLFiles: [
    "ALL_USEROBJECTPERMISSION_SLIM",
    "ALL_USEROBJECTPERMISSION",
    "ASSIGN_MODEL_PERMISSION",
    "DELETE_MODEL_PERMISSION",
    "GET_OR_CREATE_USER",
    "ROLE",
    "ROLES",
  ],
};
